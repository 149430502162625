<template>
  <div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      @sort-changed="sortingChanged"
      :sort-by.sync="filters.sortBy"
      no-local-sorting
      :sort-desc.sync="filters.sortDesc"
    >
      <template v-slot:cell(product_name_1)="data">
        <div class="d-flex">
          <div class="col-4">
            <b-img
              class="product-image"
              :src="data.item.img_1 || default_image"
              @error="handleImageSrc"
            />
            <b-img
              class="product-image ml-2"
              :src="data.item.img_2 || default_image"
              @error="handleImageSrc"
            />
          </div>
          <div class="text-left col-8">
            {{ data.item.product_name_1 }}
            <br />{{ data.item.product_name_2 }}
          </div>
        </div>
      </template>

      <template v-slot:cell(support)="data">
        <span> {{ data.item.support | numeral("0,0.0000") }}% </span>
      </template>
      <template v-slot:cell(confidence)="data">
        <span> {{ data.item.confidence | numeral("0,0.0000") }}% </span>
      </template>
      <template v-slot:cell(bills)="data">
        <span>
          {{ data.item.bills | numeral("0,0") }}({{ data.item.percen_bills }}%)
        </span>
      </template>
      <template v-slot:cell(total_customer)="data">
        <span>
          {{ data.item.total_customer | numeral("0,0") }}
        </span>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTakeProduct"
      @pagination="paginationProduct"
      :filter="filters"
      :rows="rows"
      :limit="2"
    />
    <bundle-ah
      :filters="filters"
      :rows="ahRows"
      :isBusy="isAhBusy"
      :items="itemsAh"
      @sort-changed="ahSorting"
      @perPageChange="handleChangeTakeProductAH"
      @pageChange="paginationProductAH"
    />
    <SideBarFilter
      id="bundleProductFilter"
      ref="bundleProductFilter"
      :filter="filters"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <label class="main-label">Bundle X</label>
        <div class="mb-3">
          <label class="main-label">AH1</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filters.request_product_x.ah_1"
            :options="options.ah_1"
            placeholder="Select AH1"
            track-by="id"
            label="name"
            @remove="(val) => removeAH(val, 1)"
            @select="(val) => changeAH(val, 1)"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label">AH2</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filters.request_product_x.ah_2"
            :options="options.ah_2"
            placeholder="Select AH2"
            track-by="id"
            label="name"
            @remove="(val) => removeAH(val, 2)"
            @select="(val) => changeAH(val, 2)"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label">AH3</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filters.request_product_x.ah_3"
            :options="options.ah_3"
            placeholder="Select AH3"
            track-by="id"
            label="name"
            @remove="(val) => removeAH(val, 3)"
            @select="(val) => changeAH(val, 3)"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label">AH4</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filters.request_product_x.ah_4"
            :options="options.ah_4"
            placeholder="Select AH4"
            track-by="id"
            label="name"
            @select="(val) => changeAH(val, 4)"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label">Barcode / Product</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-if="isFilterProduct"
            v-model="filters.request_product_x.ProductFilter"
            :options="options.product"
            placeholder="Select Barcode / Product"
            track-by="id"
            label="productName"
          ></multiselect>
        </div>
        <label class="main-label">Bundle Y</label>
        <div class="mb-3">
          <label class="main-label">AH1</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filters.request_product_y.ah_1"
            :options="options_y.ah_1"
            placeholder="Select AH1"
            track-by="id"
            label="name"
            @remove="(val) => removeAHY(val, 1)"
            @select="(val) => changeAHY(val, 1)"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label">AH2</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filters.request_product_y.ah_2"
            :options="options_y.ah_2"
            placeholder="Select AH2"
            track-by="id"
            label="name"
            @remove="(val) => removeAHY(val, 2)"
            @select="(val) => changeAHY(val, 2)"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label">AH3</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filters.request_product_y.ah_3"
            :options="options_y.ah_3"
            placeholder="Select AH3"
            track-by="id"
            label="name"
            @remove="(val) => removeAHY(val, 3)"
            @select="(val) => changeAHY(val, 3)"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label">AH4</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filters.request_product_y.ah_4"
            :options="options_y.ah_4"
            placeholder="Select AH4"
            track-by="id"
            label="name"
            @select="(val) => changeAHY(val, 4)"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label">Barcode / Product</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-if="isFilterProduct"
            v-model="filters.request_product_y.ProductFilter"
            :options="options.product"
            placeholder="Select Barcode / Product"
            track-by="id"
            label="productName"
          ></multiselect>
        </div>

        <check-box-anonymous
          :value="filters.allow_anonymous"
          @change="(val) => (filters.allow_anonymous = val)"
          class="mb-3"
        />
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BundleAh from "./BundleAH.vue";
export default {
  components: { Multiselect, BundleAh },
  created() {
    this.getInfo();
    this.getData();
    this.getAH();
    this.getProductFilter();
    this.getProductAHData();
  },
  data() {
    return {
      fields: [
        {
          key: "product_name_1",
          label: "Product Name X,Y",
          thClass: "w-3",
        },
        {
          key: "support",
          label: "% Bundle",
          // tdClass: "text-left",
          class: "w-1",
          sortable: true,
        },
        {
          key: "confidence",
          label: "Likelihood to buy Y if buy X",
          class: "w-1",
          sortable: true,
        },
        {
          key: "lift",
          label: "Likelihood to buy together",
          class: "w-1",
          sortable: true,
        },
        // {
        //   key: "total_count",
        //   label: "Total Orders",
        //   class: "w-1",
        //   sortable: true,
        // },
        {
          key: "total_customer",
          label: "Total Customer",
          class: "w-1",
          sortable: true,
        },
      ],
      rows: 0,
      items: [],
      filters: {
        allow_anonymous: this.$store.state.filter.allow_anonymous,
        request_product_x: {
          ah_1: [], // list ah_id
          ah_2: [], // list ah_id
          ah_3: [], // list ah_id
          ah_4: [], // list ah_id
          ProductFilter: [],
        },
        request_product_y: {
          ah_1: [], // list ah_id
          ah_2: [], // list ah_id
          ah_3: [], // list ah_id
          ah_4: [], // list ah_id
          ProductFilter: [],
        },
        page: 1,
        take: 5,
        ahpage: 1,
        ahtake: 5,
        SortColumnId: 0,
        ahSortColumnId: 0,
        SortType: true, // true = ASC, false = DESC
        ahSortType: true, // true = ASC, false = DESC
      },
      filterFreeze: {
        allow_anonymous: this.$store.state.filter.allow_anonymous,
        request_product_x: {
          ah_1: [], // list ah_id
          ah_2: [], // list ah_id
          ah_3: [], // list ah_id
          ah_4: [], // list ah_id
          ProductFilter: [],
        },
        request_product_y: {
          ah_1: [], // list ah_id
          ah_2: [], // list ah_id
          ah_3: [], // list ah_id
          ah_4: [], // list ah_id
          ProductFilter: [],
        },
        page: 1,
        take: 5,
        SortColumnId: 0,
        SortType: true, // true = ASC, false = DESC
      },
      isBusy: false,
      ahAll: {
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
      },
      ahAllY: {
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
      },
      options: {
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],

        product: [],
      },
      options_y: {
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
      },
      isFilterProduct: false,
      ahRows: 0,
      isAhBusy: false,
      itemsAh: [],
    };
  },
  computed: {
    ah_1_key() {
      return this.filters.request_product_x[`ah_1`].map(({ key }) => key) || [];
    },
    ah_2_key() {
      return this.filters.request_product_x[`ah_2`].map(({ key }) => key) || [];
    },
    ah_3_key() {
      return this.filters.request_product_x[`ah_3`].map(({ key }) => key) || [];
    },
    ah_4_key() {
      return this.filters.request_product_x[`ah_4`].map(({ key }) => key) || [];
    },
    ah_key() {
      return [
        ...this.ah_1_key,
        ...this.ah_2_key,
        ...this.ah_3_key,
        ...this.ah_4_key,
      ];
    },
    ah_1_key_y() {
      return this.filters.request_product_y[`ah_1`].map(({ key }) => key) || [];
    },
    ah_2_key_y() {
      return this.filters.request_product_y[`ah_2`].map(({ key }) => key) || [];
    },
    ah_3_key_y() {
      return this.filters.request_product_y[`ah_3`].map(({ key }) => key) || [];
    },
    ah_4_key_y() {
      return this.filters.request_product_y[`ah_4`].map(({ key }) => key) || [];
    },
    ah_key_y() {
      return [
        ...this.ah_1_key_y,
        ...this.ah_2_key_y,
        ...this.ah_3_key_y,
        ...this.ah_4_key_y,
      ];
    },
  },
  methods: {
    async getProductFilter() {
      const res = await this.$report.post(
        `/RevenueReport/CustomProductFilter`,
        {
          SearchProduct: "",
          PageNumber: 1,
          RecordPerpage: 9999,
        }
      );
      let products = res.data.productFilterModels.map((el) => {
        return { id: el.id, productName: `${el.barcode} / ${el.productName}` };
      });
      this.options.product = products;
      this.isFilterProduct = true;
    },
    async getAH() {
      const res = await this.$report(`/DashBoard/summary/ah_filter`);
      var temp_a = JSON.parse(JSON.stringify(res.data.detail));
      var temp_b = JSON.parse(JSON.stringify(res.data.detail));
      this.ahAll = temp_a;
      this.ahAllY = temp_b;
      this.options.ah_1 = this.ahAll.ah_1;
      this.options.ah_2 = this.ahAll.ah_2;
      this.options.ah_3 = this.ahAll.ah_3;
      this.options.ah_4 = this.ahAll.ah_4;
      this.options_y.ah_1 = this.ahAllY.ah_1;
      this.options_y.ah_2 = this.ahAllY.ah_2;
      this.options_y.ah_3 = this.ahAllY.ah_3;
      this.options_y.ah_4 = this.ahAllY.ah_4;
    },

    async getInfo() {
      const res = await this.$report(`/ProductMenu/bundle_product_info`);

      this.info = res.data.detail;
    },
    async getData() {
      this.isBusy = true;
      let payload = { ...this.filterFreeze };
      payload.SortColumnId = this.filters.SortColumnId;
      payload.SortType = this.filters.SortType;
      payload.PageNumber = this.filters.page;
      payload.RecordPerPage = this.filters.take;
      const res = await this.$report.post(
        `/ProductMenu/recommend_bundle_product`,
        payload
      );
      this.items = res.data.detail.data;

      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    async getProductAHData() {
      this.isAhBusy = true;
      let payload = { ...this.filterFreeze };
      console.log(this.filters);
      payload.SortColumnId = this.filters.ahSortColumnId;
      payload.SortType = this.filters.ahSortType;
      payload.PageNumber = this.filters.ahpage;
      payload.RecordPerPage = this.filters.ahtake;
      const res = await this.$report.post(
        `/ProductMenu/recommend_bundle_product_ah`,
        payload
      );
      this.isAhBusy = false;
      this.itemsAh = res.data.detail.data;
      this.ahRows = res.data.detail.total;
    },
    filterActions() {
      this.filterFreeze = JSON.parse(JSON.stringify(this.filters));
      for (const [key, value] of Object.entries(this.filters)) {
        if (typeof value == "object") {
          for (const [keys, values] of Object.entries(value)) {
            this.filterFreeze[key][keys] = values.map((el) => el.id);
          }
        }
      }
      this.getData();
    },
    clearFilter() {
      this.filter = {
        allow_anonymous: this.$store.state.filter.allow_anonymous,
        request_product_x: {
          ah_1: [], // list ah_id
          ah_2: [], // list ah_id
          ah_3: [], // list ah_id
          ah_4: [], // list ah_id
          ProductFilter: [],
        },
        request_product_y: {
          ah_1: [], // list ah_id
          ah_2: [], // list ah_id
          ah_3: [], // list ah_id
          ah_4: [], // list ah_id
          ProductFilter: [],
        },
        page: 1,
        take: 5,
        SortColumnId: 0,
        SortType: true, // true = ASC, false = DESC
      };
      this.filterFreeze = {
        allow_anonymous: this.$store.state.filter.allow_anonymous,
        request_product_x: {
          ah_1: [], // list ah_id
          ah_2: [], // list ah_id
          ah_3: [], // list ah_id
          ah_4: [], // list ah_id
          ProductFilter: [],
        },
        request_product_y: {
          ah_1: [], // list ah_id
          ah_2: [], // list ah_id
          ah_3: [], // list ah_id
          ah_4: [], // list ah_id
          ProductFilter: [],
        },
        page: 1,
        take: 5,
        SortColumnId: 0,
        SortType: true, // true = ASC, false = DESC
        ahpage: 1,
        ahtake: 5,
        SortColumnId: 0,
        ahSortColumnId: 0,
      };
      this.$refs.bundleProductFilter.hide();
      this.getData();
    },
    paginationProduct(val) {
      this.filters.page = val;
      this.getData();
    },
    handleChangeTakeProduct(val) {
      this.filters.take = val;
      this.filters.page = 1;
      this.getData();
    },
    paginationProductAH(val) {
      this.filters.ahpage = val;
      this.getProductAHData();
    },
    handleChangeTakeProductAH(val) {
      console.log(val);
      this.filters.ahtake = val;
      this.filters.ahpage = 1;
      this.getProductAHData();
    },
    sortingChanged(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index;

      if (
        this.filters.SortType &&
        !ctx.sortDesc &&
        this.filters.SortColumnId == index
      ) {
        this.filters.SortColumnId = 0;
        this.filters.sortBy = "";
        this.filters.sortDesc = "";
      } else {
        this.filters.SortColumnId = index;
        this.filters.SortType = ctx.sortDesc;
      }
      this.getData();
    },
    ahSorting(ctx) {
      let index = this.ahFields.findIndex((el) => el.key == ctx.sortBy);
      index = index;

      if (
        this.filters.ahSortType &&
        !ctx.ahsortDesc &&
        this.filters.ahSortColumnId == index
      ) {
        this.filters.ahSortColumnId = 0;
        this.filters.ahsortBy = "";
        this.filters.ahsortDesc = "";
      } else {
        this.filters.ahSortColumnId = index;
        this.filters.ahSortType = ctx.ahsortDesc;
      }
      this.getData();
    },
    handleSearch() {
      this.getData();
    },
    autoSearch() {
      this.getData();
    },
    async removeAH(selected, number) {
      let maxIndex = number + 1;
      for (let index = 1; index < 4; index++) {
        if (this.filters.request_product_x[`ah_${index}`].length) {
          maxIndex = index;
        }
      }
      for (let index = number; index < 4; index++) {
        let nextIndex = index + 1;
        this.filters.request_product_x[`ah_${nextIndex}`] =
          await this.filters.request_product_x[`ah_${nextIndex}`].filter(
            (item) => item[`ah_${number}_ahid`] != selected.id
          );
        if (this.filters.request_product_x[`ah_${index}`].length == 0) {
          maxIndex = index;
        }
        this.options[`ah_${nextIndex}`] = await this.handleListAH(
          nextIndex,
          maxIndex,
          this.ah_key
        );
      }
    },
    async changeAH(selected, number) {
      let maxIndex = number + 1;
      for (let index = number; index < 4; index++) {
        let nextIndex = index + 1;
        if (nextIndex <= 4)
          this.options[`ah_${nextIndex}`] = await this.handleListAH(
            nextIndex,
            maxIndex,
            this.ah_key
          );
      }
    },
    async handleListAH(nextIndex, maxIndex, lastKey) {
      let list = await this.ahAllY[`ah_${nextIndex}`];
      if (lastKey.length) {
        list = await this.ahAllY[`ah_${nextIndex}`].filter((item) => {
          let ah = "AH";
          for (let i = 1; i < maxIndex; i++) {
            ah += item[`ah_${i}_ahid`];
          }
          let result = lastKey.indexOf(ah);
          return result > -1;
        });
      }
      return list;
    },
    async removeAHY(selected, number) {
      let maxIndex = number + 1;
      for (let index = 1; index < 4; index++) {
        if (this.filters.request_product_y[`ah_${index}`].length) {
          maxIndex = index;
        }
      }
      for (let index = number; index < 4; index++) {
        let nextIndex = index + 1;
        this.filters.request_product_y[`ah_${nextIndex}`] =
          await this.filters.request_product_y[`ah_${nextIndex}`].filter(
            (item) => item[`ah_${number}_ahid`] != selected.id
          );
        if (this.filters.request_product_y[`ah_${index}`].length == 0) {
          maxIndex = index;
        }
        this.options_y[`ah_${nextIndex}`] = await this.handleListAHY(
          nextIndex,
          maxIndex,
          this.ah_key_y
        );
      }
    },
    async changeAHY(selected, number) {
      let maxIndex = number + 1;
      for (let index = number; index < 4; index++) {
        let nextIndex = index + 1;
        if (nextIndex <= 4)
          this.options_y[`ah_${nextIndex}`] = await this.handleListAHY(
            nextIndex,
            maxIndex,
            this.ah_key_y
          );
      }
    },
    async handleListAHY(nextIndex, maxIndex, lastKey) {
      let list = await this.ahAllY[`ah_${nextIndex}`];
      if (lastKey.length) {
        list = await this.ahAllY[`ah_${nextIndex}`].filter((item) => {
          let ah = "AH";
          for (let i = 1; i < maxIndex; i++) {
            ah += item[`ah_${i}_ahid`];
          }
          let result = lastKey.indexOf(ah);
          return result > -1;
        });
      }
      return list;
    },
  },
};
</script>

<style></style>
