<template>
  <div>
    <HeaderPanel
      :title="headerText"
      @sidebar="showFilter"
      :hasDropdown="false"
      :hasSearch="false"
      :hasExport="false"
      :hasFilter="tabIndex == 3 ? false : true"
      :class="tabIndex == 3 ? 'mb-2' : ''"
    />
    <div class="my-2 f-16" v-if="tabIndex == 2">
      <div v-if="info.totalTransaction">
        Total Orders:
        {{ info.totalTransaction | numeral("0,0") }}
      </div>
    </div>

    <p class="my-2 f-16" v-if="info.dateFrom && tabIndex == 2">
      {{ info.dateFrom | moment($formatDate) }} -
      {{ info.dateTo | moment($formatDate) }}
    </p>
    <p class="my-2 f-16" v-else-if="tabIndex == 1">
      {{ daysince.PurchaseFrom | moment($formatDate) }} -
      {{ daysince.PurchaseTo | moment($formatDate) }}
    </p>
    <p class="my-2 f-16" v-else-if="tabIndex == 0 && $refs.repurchase">
      {{ repurchaseDate.PurchaseFrom | moment($formatDate) }} -
      {{ repurchaseDate.PurchaseTo | moment($formatDate) }}
    </p>
    <b-tabs v-model="tabIndex" content-class="bg-white p-3 ">
      <b-tab title="Repurchase">
        <CustomerBehavior ref="repurchase" />
      </b-tab>
      <b-tab title="Day Since First Purchase">
        <DaySince ref="DaySince" v-if="tabIndex == 1" @set="setDaySinceDate" />
      </b-tab>
      <b-tab title="Bundle Product">
        <div>
          <Bundle ref="bundle" v-if="tabIndex == 2" />
        </div>
      </b-tab>
      <b-tab title="Product Journey">
        <SecondPurchase ref="secondPurchase" v-if="tabIndex == 3" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>


import CustomerBehavior from "./customerBehavior";
import SecondPurchase from "./secondPurchase";
import Bundle from "./Bundle";
import DaySince from "./DaySince";
export default {
  components: {

    CustomerBehavior,
    SecondPurchase,
    DaySince,
    Bundle,
  },
  data() {
    return {
      info: {},
      tabIndex: 0,
      daysince: { PurchaseFrom: "", PurchaseTo: "" },
    };
  },
  created() {},
  watch: {
    // tabIndex(val) {
    //   if (val == 2) {
    //   }
    // },
  },
  computed: {
    headerText() {
      if (this.tabIndex == 0) return "Repurchase";
      if (this.tabIndex == 1) return "Day Since First Purchase";
      if (this.tabIndex == 2) return "Bundle Product";
      return "Product Journey";
    },
    repurchaseDate() {
      return this.$refs.repurchase ? this.$refs.repurchase.filter : "";
    },
  },
  methods: {
    setDaySinceDate(val) {
      this.daysince = val;
      return val;
    },

    showFilter() {
      console.log(this.$refs.bundle);
      if (this.tabIndex == 1) return this.$refs.DaySince.showFilter();
      return this.tabIndex == 0
        ? this.$refs.repurchase.showFilter()
        : this.$refs.bundle.$refs.bundleProductFilter.show();
    },
  },
};
</script>

<style>
.product-image {
  width: auto;
  height: 50px;
  aspect-ratio: 1;
}
</style>
