<template>
  <div>
    <!-- <pre>{{ data }}</pre> -->
    <JSCharting
      :options="chartOptions"
      class="graph-container"
      v-if="!isLoading"
    ></JSCharting>

    <OtherLoading v-else class="graph-container" />
    <SideBarFilter
      id="repurchaseFilter"
      ref="repurchaseFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <InputDatePickerFilter
          textFloat="First Purchase From"
          name="first-purchase-from"
          ref="first-purchase-from"
          class="mb-3"
          :value="filter.PurchaseFrom"
          :max-datetime="filter.PurchaseTo"
          @input="(val) => (filter.PurchaseFrom = val)"
        />

        <InputDatePickerFilter
          textFloat="First Purchase To"
          name="first-purchase-to"
          ref="first-purchase-to"
          :value="filter.PurchaseTo"
          class="mb-3"
          @input="(val) => (filter.PurchaseTo = val)"
        />
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="First Purchase Channel"
            :options="options.channelTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.PurchaseChannel"
            :value="filter.PurchaseChannel"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="First Purchase Branch"
            :options="options.branches"
            textField="branchName"
            valueField="id"
            placeholder="Select Branch"
            v-model="filter.PurchaseBranchGroup"
            :value="filter.PurchaseBranchGroup"
          />
        </div>
        <check-box-anonymous
          :value="filter.allow_anonymous"
          @change="(val) => (filter.allow_anonymous = val)"
          class="mb-3"
        />
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import JSCharting from "jscharting-vue";
export default {
  components: {
    JSCharting,
    OtherLoading,
  },
  data() {
    return {
      chartOptions: {
        debug: true,
        id: "daysince-chart",
        type: "heatmap",
        toolbar_visible: false,
        // title_label_text: "",
        title_label_text: `Day since first purchase`,
        legend_title_label_text: "",
        // legend: { height: 800 },
        palette: {
          colors: ["#fcfed1", "#46b8c3", "#0d2162"],
          pointValue: "{%zValue}",
        },
        defaultPoint: {
          tooltip: "<b>%xValue</b> %yValue<br/>accumulate: %zValue",
          label_text: "%zValue",
          outline_width: 0,
        },
        yAxis_label_text: "Cohort (Month and Year of First Purchase)",
        xAxis_label_text: "",
        defaultPoint_outline_color: "none",
        series: [
          {
            name: "Call Response Time.",
            points: [
              { x: "Josh", y: "Monday", z: 1 },
              { x: "Josh", y: "Tuesday", z: 6 },
              { x: "Josh", y: "Wednesday", z: 3 },
              { x: "Josh", y: "Thursday", z: 2 },
              { x: "Josh", y: "Friday", z: 5 },
              { x: "Josh", y: "Saturday", z: 1 },
              { x: "Greg", y: "Monday", z: 4 },
              { x: "Greg", y: "Tuesday", z: 6 },
              { x: "Greg", y: "Wednesday", z: 1 },
              { x: "Greg", y: "Thursday", z: 8 },
              { x: "Greg", y: "Friday", z: 6 },
              { x: "Greg", y: "Saturday", z: 1 },
              { x: "Molly", y: "Monday", z: 2 },
              { x: "Molly", y: "Tuesday", z: 2 },
              { x: "Molly", y: "Wednesday", z: 3 },
              { x: "Molly", y: "Thursday", z: 1 },
              { x: "Molly", y: "Friday", z: 2 },
              { x: "Molly", y: "Saturday", z: 2 },
            ],
          },
        ],
      },

      filter: {
        PurchaseFrom: this.$moment().subtract(1, "year").format("YYYY-MM-DD"),
        PurchaseTo: this.$moment().format("YYYY-MM-DD"),
        PurchaseChannel: [],
        PurchaseBranchGroup: [],
        allow_anonymous: this.$store.state.filter.allow_anonymous,
        MinPurchase: 2,
      },
      isLoading: true,
      options: { branches: [], channelTypes: [], MinPurchase: [] },
    };
  },
  created() {
    this.getData();
    this.getFilter();
    // this.convertData();
  },
  methods: {
    async getFilter() {
      const res = await this.$report(`/RevenueReport/CustomFilter`);

      this.options = {
        ...res.data,
      };

      // this.isFilterLoading = false;
    },
    async getData() {
      this.isLoading = true;
      const res = await this.$report.post(
        "/dashboard/daysincefirstpurchase",
        this.filter
      );
      this.data = res.data.detail;
      await this.convertData();
      this.isLoading = false;
    },
    async convertData() {
      let point = [];
      var avg = [];

      for (const month of this.data.month) {
        for (const value of month.value) {
          point.unshift({
            y: `${month.name} (n=${month.first_purchase})`,
            x: this.convertText(value.cohort),
            z: value.accumulate,
          });
        }
      }

      for (const average of this.data.average.value) {
        avg.push({
          y: `Average`,
          x: this.convertText(average.cohort),
          z: average.accumulate,
        });
      }

      point = [...avg, ...point];
      this.$emit("set", this.filter);
      this.chartOptions.series[0].points = point;
    },
    convertText(val) {
      if (val == 1) return `${val}st`;
      else if (val == 2) return `${val}nd`;
      else if (val == 3) return `${val}rd`;
      else return `${val}th`;
    },
    clearFilter() {
      this.filter = {
        PurchaseFrom: this.$moment().subtract(1, "year").format("YYYY-MM-DD"),
        PurchaseTo: this.$moment().format("YYYY-MM-DD"),
        PurchaseChannel: [],
        PurchaseBranchGroup: [],
        allow_anonymous: this.$store.state.filter.allow_anonymous,
        MinPurchase: 2,
      };
      this.getData();
      this.$refs.repurchaseFilter.hide();
    },
    filterActions() {
      //   this.chartOptions.title_label_text = `Accumulated Percentage of Unique Users(Minimun ${this.filter.MinPurchase} Purchases) for Each Cohort`;
      this.getData();
    },
    showFilter() {
      this.$refs.repurchaseFilter.show();
    },
  },
};
</script>

<style>
.graph-container {
  height: 600px;
  width: 100%;
}
</style>
